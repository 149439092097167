.region-row {
  min-height: 72px;
}

.region-row__main-cell {
  min-width: 112px;
}

.region-row__precture-cell {
  background-color: #fafafa;
}

.top-list__icon {
  max-height: 24px;
}

.top-list__cell {
  background-color: #fafafa;
  border-left: 4px solid #2b91d7;
}

.header {
  border-top: 4px solid #2b91d7;
  border-bottom: 8px solid #2b91d7;
}

.header__logo {
  max-width: 264px;
}

@media screen and (max-width: 499px) {
  .header__logo {
    max-width: 200px;
  }
}
.main {
  min-height: 80vh;
  background-color: #f2f2f2;
}

.main.main--bg-white {
  background-color: #fff;
}

.footer {
  border-top: 4px solid #2b91d7;
}

.footer__logo {
  max-width: 240px;
}

.form__label {
  background-color: #fdf6f2;
}

.u-text-link {
  position: relative;
  font-size: 12px;
}

.u-text-link::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -12px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 4px 0 4px 8px;
  border-color: transparent transparent transparent #2b91d7;
}

.u-bg-tohoku {
  color: #fff;
  background-color: #967bbf;
}

.u-bg-kanto {
  color: #fff;
  background-color: #7b8fbf;
}

.u-bg-hokuriku {
  color: #fff;
  background-color: #4b9bcd;
}

.u-bg-tokai {
  color: #fff;
  background-color: #60b59b;
}

.u-bg-kinki {
  color: #fff;
  background-color: #74ae43;
}

.u-bg-tyugoku {
  color: #fff;
  background-color: #ecc36a;
}

.u-bg-shikoku {
  color: #fff;
  background-color: #eca66a;
}

.u-bg-kyusyu {
  color: #fff;
  background-color: #ec7e6a;
}

.u-title {
  border-bottom: 4px solid #2b91d7;
}

.u-heading span {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 14px;
  color: #008fdd;
  text-shadow: none;
}

.u-heading {
  position: relative;
  font-weight: bold;
  padding-bottom: 50px;
  text-shadow: 1px 1px 0 #008fdd;
}

.u-heading::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%) rotate(30deg);
  width: 2px;
  height: 40px;
  background-color: #008fdd;
}