.header {
  border-top: 4px solid #2b91d7;
  border-bottom: 8px solid #2b91d7;
}

.header__logo {
  max-width: 264px;
}
@media screen and (max-width: 499px) {
  .header__logo {
    max-width: 200px;
  }
}
