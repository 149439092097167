.u-bg-tohoku {
  color: #fff;
  background-color: #967bbf;
}

.u-bg-kanto {
  color: #fff;
  background-color: #7b8fbf;
}

.u-bg-hokuriku {
  color: #fff;
  background-color: #4b9bcd;
}

.u-bg-tokai {
  color: #fff;
  background-color: #60b59b;
}

.u-bg-kinki {
  color: #fff;
  background-color: #74ae43;
}

.u-bg-tyugoku {
  color: #fff;
  background-color: #ecc36a;
}

.u-bg-shikoku {
  color: #fff;
  background-color: #eca66a;
}

.u-bg-kyusyu {
  color: #fff;
  background-color: #ec7e6a;
}
