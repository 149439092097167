.region-row {
  min-height: 72px;
}

.region-row__main-cell {
  min-width: 112px;
}

.region-row__precture-cell {
  background-color: #fafafa;
}

.top-list__icon {
  max-height: 24px;
}

.top-list__cell {
  background-color: #fafafa;
  border-left: 4px solid #2b91d7;
}
