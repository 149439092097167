.u-heading span {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 14px;
  color: #008fdd;
  text-shadow: none;
}
.u-heading {
  position: relative;
  font-weight: bold;
  padding-bottom: 50px;
  text-shadow: 1px 1px 0 #008fdd;
}
.u-heading::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%) rotate(30deg);
  width: 2px;
  height: 40px;
  background-color: #008fdd;
}
