.u-text-link {
  position: relative;
  font-size: 12px;
}

.u-text-link::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -12px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 4px 0 4px 8px;
  border-color: transparent transparent transparent #2b91d7;
}
